import { useEffect, useState } from "react";
import { calories } from "../api.js";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { BeatLoader } from "react-spinners";
import "../Styles/calories.css";

const Calories = () => {
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    calories().then((response) => {
      if (response.data.data.length === 0) {
        setMenu([]);
        setLoading(false);
      } else {
        const result = Object.values(response.data.data);

        let mealArr = [];

        for (let i = 0; i < result.length; i++) {
          let obj = {};
          let marginTop =
            result[i].allergens.length > 1 ? result[i].allergens.length * 6 : 1;

          // For display
          obj.nameAr = (
            <p className="nameAr"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: `${marginTop}px`,
              }}
            >
              {result[i].nameAr}
            </p>
          );
          obj.allergens = result[i].allergens.map((e) => (
            <div key={e.name}>{e.name}</div>
          ));

          // For search (hidden plain text values)
          obj.searchNameAr = result[i].nameAr;
          obj.searchAllergens = result[i].allergens
            .map((e) => e.name)
            .join(", ");

          mealArr.push(obj);
        }

        setMenu(mealArr);
        setLoading(false);
      }
    });
  }, []);

  const data = {
    columns: [
      {
        label: "الوجبة",
        field: "nameAr",
        sort: "asc",
      },
      {
        label: "مسببات الحساسية",
        field: "allergens",
        sort: "asc",
      },
    ],
    rows: menu.map((item) => ({
      nameAr: item.nameAr,
      allergens: item.allergens,
      searchNameAr: item.searchNameAr,
      searchAllergens: item.searchAllergens,
    })),
  };

  return (
    <div className="responsive-container">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="table-wrapper">
          <MDBDataTable
          responsive={true}
          responsiveLg={true}
          responsiveSm={true}
          responsiveMd={true}
          fixed={true}
            searchLabel={t('search')}
            paginationLabel={["السابق", "التالي"]}
            entriesLabel="عرض"
            striped
            className="table"
            hover
            searching={true}
            noBottomColumns={true}
            data={data}
            paging={false}
            searchTop
          />
        </div>
      )}
      <style jsx>
        
        {`



      `}</style>
    </div>
  );
};

export default Calories;
