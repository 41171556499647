import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Get_Wallet, getUserByPhone, sendMoneyToUser } from "../api";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import "../Styles/UserProfile.css";
import coin from "../Images/TransactionCoin.svg";
import bg from "../Images/TransactionBg.svg";
import transferMoney from "../Images/transfermony.png";
import successWeb from "../Images/success-web.svg";
import notfound from "../Images/not-found.png";

function Transactions(props) {
  // React hooks and states
  const { t, i18n } = useTranslation();
  const [wallet, setWallet] = useState(0);
  const [walletResponse, setWalletResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [transferLoading, setTransferLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [walletObject, setWalletObjet] = useState(null)

  const [transferForName, setTransferForName] = useState(null);
  const [transferForNumber, setTransferForNumber] = useState(null);
  const token = window.localStorage.getItem("token");

  const dialogRef = useRef(null);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setStep(1);
    setTransferForName(null);
    setTransferForNumber(null);
    setTransferLoading(false)


    setStep(1);
    setTransferForName(null);
    setTransferForNumber(null);
    setAmount(null);
    setPhoneNumber(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    if (showDialog) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDialog]);

  // Fetch wallet data on component mount
  useEffect(() => {
    setLoading(true);

    const countryId = props.country === '0' ? 1 : 2;
    Get_Wallet(token, countryId)
      .then((response) => {
        console.log(response.data.data);
        setWalletResponse(response.data.data);
        setTransactions(response.data.data.transactions);
        setWalletObjet(response.data.data)
        console.log(transactions);
        if (response.data.data.balance) {
          setWallet(parseFloat(response.data.data.balance).toFixed(2));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.country, step]);

  const handleContinue = () => {
    if (phoneNumber != null && amount != null) {
      if (wallet >= parseFloat(amount)) {
        const userPhone = localStorage.getItem("userPhone");

        console.log(wallet );
        console.log(amount)
        

        if (userPhone === phoneNumber) {
          setStep(6);
          return;
        }
        if(walletObject.minTransferAmount > parseFloat(amount) || walletObject.maxTransferAmount <amount) {
          setStep(6);
          return;
        }

        const token = localStorage.getItem("token");
        const data = { phone: phoneNumber };

        getUserByPhone(data, token)
          .then((res) => {
            setTransferForName(res.data.data.name);
            setTransferForNumber(res.data.data.phone);
            setStep(2);
          })
          .catch((e) => {
            setStep(4);
          });
      } else {
        setStep(4);
      }
    }
  };

  const handleInvite = () => {
    setStep(5);
  };

  const handleTransfer = () => {
    const data = { phone: transferForNumber, amount: amount };
    setTransferLoading(true);
  
    sendMoneyToUser(data, token)
      .then((res) => {
        if (res.data.message === "success") {
          setTransferLoading(false);
          setStep(3);
          setTransferForNumber(null);
          setTransferForName(null);
        }
      })
      .catch((e) => {
        if (e.response.data.error === "transfers limits exceeded") {
          setStep(7);
          setLoading(false);
          setTransferLoading(false);
          return;
        }
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          closeDialog();
        }, 1000);
        setTransferLoading(false);
        console.log(e);
      });
  };
  
  const handleChangeAmount = (e) => {
    const enteredValue = e.target.value;
    if (/^\d*$/.test(enteredValue)) {
      setAmount(enteredValue);
    }
  };

  const handleChangePhone = (e) => {
    const enteredValue = e.target.value;
    if (/^\d*$/.test(enteredValue)) {
      setPhoneNumber(enteredValue);
      if (enteredValue.length <= 8) {
        setErrorMessage(t("validation.phone_not_valid"));
      } else {
        setErrorMessage("");
      }
    }
  };

  const copyAndClose = () => {
    navigator.clipboard
      .writeText("https://rrc.link/54q25")
      .then(() => {
        closeDialog();
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    if (i18n.language === "ar") {
      return newDate.toLocaleDateString("ar-EG", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    } else {
      return `${newDate.getDate()} ${newDate.toLocaleString("default", {
        month: "short",
      })} ${newDate.getFullYear()}`;
    }
  };

  return (
    <div>
      {modal && <Logout setModal={setModal} setCartItem={props.setCartItem} />}
      {showDialog && (
        <div className="roundedDialog">
          <div ref={dialogRef}>
            {step === 1 && (
              <div className="modal-content1">
                <div className="dialog-header">
                  <img src={transferMoney} alt="Transfer Money" />
                  <h5 className="text-center">
                    <p style={{ fontWeight: "bold", fontFamily: "cairo" }}>
                      {t("profile.sendMoney")}
                    </p>
                  </h5>
                  <h5 className="text-center">
                    <p style={{ color: "#828282", fontFamily: "cairo" }}>
                      {t("enterPhoneWeWillSend")}
                    </p>
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <div className="col-sm-12">
                      <label
                        style={{ fontFamily: "cairo", marginBottom: "1vw" }}
                        htmlFor="customer-name"
                      >
                        {t("phoneNumber")}
                      </label>
                      <input
                        onChange={handleChangePhone}
                        type="text"
                        maxLength="10"
                        value={phoneNumber}
                        className="form-control rounded"
                        id="customer-name"
                        placeholder={t("enterPhoneNumber")}
                      />
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: "2vw" }} className="form-group">
                    <div className="col-sm-12">
                      <label
                        style={{ fontFamily: "cairo", marginBottom: "1vw" }}
                        htmlFor="customer-name"
                      >
                        {t("amount")}
                      </label>
                      <input
                        onChange={handleChangeAmount}
                        type="text"
                        value={amount}
                        className="form-control rounded"
                        id="customer-name"
                        placeholder={t("enterAmount")}
                      />
                    </div>
                  </div>
                  <div className="btn-transfer">
                    <div
                      onClick={
                        errorMessage !== "" ? null : () => handleContinue()
                      }
                      className="confirm-transfer"
                    >
                      <p>{t("continue")}</p>
                    </div>
                    <div onClick={closeDialog} className="cancel-transfer">
                      <p>{t("cancel")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div style={{ height: "55vh" }} className="modal-content1">
                <div className="dialog-header">
                  <h5 style={{ marginTop: "5vh" }} className="text-center">
                    <p style={{ fontWeight: "bold", fontFamily: "cairo" }}>
                      {t("confirmTransfer")}
                    </p>
                  </h5>
                  <h5 className="text-center">
                    <p style={{ color: "#828282", fontFamily: "cairo" }}>
                      {t("willsendAmount")} {amount} {t("to")} {transferForName}
                    </p>
                  </h5>
                  <h5 className="text-center">
                    <p style={{ color: "#828282", fontFamily: "cairo" }}>
                      {t("phoneNumber")}: {transferForNumber}
                    </p>
                  </h5>
                </div>
                <div className="modal-body">
                  <div style={{ marginTop: "5vh" }} className="btn-transfer">
                    <div
                      onClick={!transferLoading ? handleTransfer : null}
                      className="confirm-transfer"
                    >
                      <p>
                        {transferLoading ? <BeatLoader /> : t("transferMoney")}
                      </p>
                    </div>
                    <div onClick={closeDialog} className="cancel-transfer">
                      <p>{t("cancel")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div style={{ height: "55vh" }} className="modal-content1">
                <div className="dialog-header">
                  <img src={successWeb} alt="Success" height="80vh" />
                  <h5 style={{ marginTop: "7vh" }} className="text-center">
                    <p style={{ fontWeight: "bold", fontFamily: "cairo" }}>
                      {t("moneyHasTransfered")}
                    </p>
                  </h5>
                  <h5 className="text-center">
                    <p style={{ color: "#828282", fontFamily: "cairo" }}>
                      {t("weAddTheTransfer")}
                    </p>
                  </h5>
                </div>
                <div className="modal-body">
                  <div style={{ marginTop: "1vh" }} className="btn-transfer">
                    <div onClick={closeDialog} className="cancel-transfer">
                      <p>{t("cancel")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 4 && (
              <div style={{ height: "35vh" }} className="modal-content1">
                <div className="dialog-header">
                  <h5 style={{ marginTop: "3vh" }} className="text-center">
                    <p style={{ fontWeight: "bold", fontFamily: "cairo" }}>
                      { 
                      wallet <= parseFloat(amount)
                        ? t("nosufficient")
                        : t("phoneNotRegist")}
                    </p>
                  </h5>
                  <h5 className="text-center">
                    <p style={{ color: "#828282", fontFamily: "cairo" }}>
                      {wallet >= parseFloat(amount) && t("doYouWantToInvite")}
                    </p>
                  </h5>
                </div>
                <div className="modal-body">
                  <div style={{ marginTop: "5vh" }} className="btn-transfer">
                    {wallet >= parseFloat(amount) && (
                      <div onClick={handleInvite} className="confirm-transfer">
                        <p>{t("inviteHim")}</p>
                      </div>
                    )}
                    <div onClick={closeDialog} className="cancel-transfer">
                      <p>{t("cancel")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 5 && (
              <div style={{ height: "35vh" }} className="modal-content1">
                <div className="dialog-header">
                  <h5 style={{ marginTop: "3vh" }} className="text-center"></h5>
                  <h5 className="text-center"></h5>
                </div>
                <div className="modal-body text-center">
                  <p style={{ fontSize: "20px", fontFamily: "cairo" }}>
                    {t("tryAlromansiah")}
                  </p>
                  <p style={{ fontFamily: "Cairo", color: "blue" }}>
                    <a target="_blank" href="https://rrc.link/54q25">
                      https://rrc.link/54q25
                    </a>
                  </p>
                </div>
                <div style={{ marginTop: "2vh" }} className="btn-transfer">
                  <div onClick={copyAndClose} className="confirm-transfer">
                    <p>{t("copy")}</p>
                  </div>
                  <div onClick={closeDialog} className="cancel-transfer">
                    <p>{t("cancel")}</p>
                  </div>
                </div>
              </div>
            )}
            {step === 6 && (
              <div style={{ height: "35vh" }} className="modal-content1">
                <div className="dialog-header">
                  <h5 style={{ marginTop: "3vh" }} className="text-center"></h5>
                  <h5 className="text-center"></h5>
                </div>
                <div className="modal-body text-center">
                  <p style={{ fontSize: "20px", fontFamily: "cairo" }}>
                    { walletObject.minTransferAmount >parseFloat(amount) ? t('sorryTheAmountIsless') : walletObject.maxTransferAmount <parseFloat(amount) ? t('sorryTheAmountIsMore'): t("cantTransferSameNumer")}
                  </p>
                </div>
                <div
                  onClick={closeDialog}
                  className="text-center cancel-transfer-1 "
                >
                  <p>{t("cancel")}</p>
                </div>
              </div>
            )}
            {step === 7 && (
              <div style={{ height: "35vh" }} className="modal-content1">
                <div className="dialog-header">
                  <h5 style={{ marginTop: "3vh", fontFamily: "cairo" }} className="text-center">
                    {t('faildTransfer')}
                  </h5>
                  <h5 className="text-center"></h5>
                </div>
                <div className="modal-body text-center">
                  <p style={{ fontSize: "20px", fontFamily: "cairo" }}>
                    {t("transfersLimitsExceeded")}
                  </p>
                </div>
                <div
                  onClick={closeDialog}
                  className="text-center cancel-transfer-1 "
                >
                  <p>{t("cancel")}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={2} />
        {loading ? (
          <div className="loader profileLoader">
            <BeatLoader color="#E47100" loading={loading} size={30} />
          </div>
        ) : (
          <div className="aboutusBody profileBody container-fluid fluid">
            <p className="upTable-title">{t("profile.transactions")}</p>
            <div className="row">
              <div className="col">
                <div
                  className={
                    i18n.language === "ar"
                      ? "transactionCredit"
                      : "transactionCredit transactionCreditEn"
                  }
                >
                  <div className="row">
                    <div className="col-lg-7">
                      <p className="tc-p1">{t("profile.current")}</p>
                      <p className="tc-p22">{wallet}</p>
                      <p className="tc-p3">
                        {i18n.language === "ar"
                          ? walletResponse.arCurrency
                          : walletResponse.enCurrency}
                      </p>
                    </div>
                    <div className="tc-img col-lg-5">
                      <img className="tc-bg" src={bg} alt="Background" />
                      <img src={coin} alt="Coin" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ margin: "4px" }} className="chrgWallet">
                <Link to="/charge-wallet">
                  <button>{t("profile.charge")}</button>
                </Link>
              </div>
              <div
                onClick={openDialog}
                style={{ margin: "4px" }}
                className="chrgWallet"
              >
                <button>{t("profile.sendMoney")}</button>
              </div>
            </div>
            <p className="upTable-title">{t("profile.walletHistory")}</p>
            <div className="row">
              <div className="col">
                <div
                  className={
                    i18n.language === "ar"
                      ? "transactionCredit"
                      : "transactionCredit transactionCreditEn"
                  }
                >
                  <div className="row">
                    <div className="">
                      <div className="transaction-history-container">
                        <table className="transaction-history">
                          <thead>
                            <tr>
                              <th>{t("profile.amount")}</th>
                              <th>{t("profile.type1")}</th>
                              <th>{t("profile.date1")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    color:
                                      transaction.type === "in"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {`${parseFloat(transaction.amount).toFixed(
                                    2
                                  )}`}{" "}
                                  {transaction.type === "in" ? "" : "-"}
                                </td>
                                <td>
                                  {transaction.notes &&
                                  transaction.notes.includes("transfer")
                                    ? t("transfer")
                                    : transaction.transactionType === null
                                    ? transaction.type === "in"
                                      ? t("transactionType.credit")
                                      : t("transactionType.deduct") // This covers "out" case
                                    : transaction.type === "out"
                                    ? t("transactionType.deduct") // Force translation for "out"
                                    : t(
                                        `transactionType.${transaction.transactionType}`
                                      )}
                                </td>
                                <td>{formatDate(transaction.createdAt)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Transactions;
